<template>
    <div id="content">
        <div class="container-fluid">
            <div class="box">
				<LoadingSpinner v-if="loading" />
				<template v-else>
	            	<div class="row">
						<div class="col-auto ml-auto d-flex">
	                		<b-button variant="primary" @click="addContentType">
	                			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
			                </b-button>
	                	</div>
	                </div>
	            	<div class="row mt-2" v-for="(type, index) in content_type" :key="index">
	                    <div class="col-6">
							<b-form-input
								v-model="type.label"
								:placeholder="$t('web_content.nom_du_champ')"
							></b-form-input>
						</div>

	            		<div class="col-6">
	                		<e-select
	                            v-model="type.type"
	                            :options="type_available"
	                            :searchable="true"
	                            :allow-empty="false"
								:placeholder="$t('web_content.type_du_champ')"
	                        >
	                            <template slot="option" slot-scope="{ option }">{{ $t('web_content.content_type.'+option) }}</template>
	                            <template slot="singleLabel" slot-scope="{ option }">{{ $t('web_content.content_type.'+option) }}</template>
	                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	                        </e-select>
	                    </div>
	            	</div>
	                <div class="row mt-2">
	                    <div class="col-12 text-center">
	                        <b-button v-if="content_type.length > 0" variant="primary" rounded-pill @click="saveConfig"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
	                    </div>
	                </div>
	            </template>
            </div>
        </div>
    </div>
</template>

<script>
import TranslationContent from '@/mixins/TranslationContent'

export default {
    name: 'WebContentType',
    mixins: [TranslationContent],
   	data () {
		return {
			type_available: ['editor', 'text'],
			content_type: [],
			loading: false,
            processing: false
		}
	},
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
        	this.loading = true
            const content_type = await this.getAllContentType()
            this.content_type = content_type.map(content => ({id: content.contenttype_id, type: content.contenttype_type, label: content.contenttype_label}))
            this.loading = false
        },

        addContentType() {
        	this.content_type.push({
        		id: null,
        		type: null,
        		label: ''
        	})
        },

        async saveConfig() {
        	if(this.processing) {
        		return false
        	}
            this.processing = true

            const content_empty = this.content_type.filter(content => content.type == null || content.label == '')
            if(content_empty.length > 0) {
            	this.failureToast("toast.all_inputs")
           		this.processing = false
            	return false
            }

            await this.saveContentType(this.content_type)

            this.processing = false
            this.init_component()
        }
    },
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
};
</script>